import React, { useCallback, useMemo, useState } from 'react';
import { bool, func, object, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';
import { ModalInMobile, Button } from '../../../components';

import css from './SearchFiltersMobile.module.css';

const SearchFiltersMobileComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    showAsModalMaxWidth,
    onMapIconClick,
    onManageDisableScrolling,
    filters,
    clearFilters,
    intl,
    onOpenModal,
    urlQueryParams,
    history,
    onCloseModal,
    resetAll,
  } = props;

  const [isFiltersOpenOnMobile, setIsFiltersOpenOnMobile] = useState(false);
  const [initialQueryParams, setInitialQueryParams] = useState(null);

  const routeConfiguration = useRouteConfiguration();

  const classes = classNames(rootClassName || css.root, className);

  const resultsFound = useMemo(
    () => (
      <FormattedMessage id="SearchFiltersMobile.foundResults" values={{ count: resultsCount }} />
    ),
    [resultsCount]
  );
  const noResults = useMemo(() => <FormattedMessage id="SearchFiltersMobile.noResults" />, []);
  const loadingResults = useMemo(
    () => <FormattedMessage id="SearchFiltersMobile.loadingResults" />,
    []
  );
  const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

  const showListingsLabel = intl.formatMessage(
    { id: 'SearchFiltersMobile.showListings' },
    { count: resultsCount }
  );

  // Open filters modal, set the initial parameters to current ones
  const openFilters = useCallback(() => {
    onOpenModal();

    setIsFiltersOpenOnMobile(true);
    setInitialQueryParams(urlQueryParams);
  }, [onOpenModal, urlQueryParams]);

  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = useCallback(() => {
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration, {}, initialQueryParams)
    );
    onCloseModal();

    setIsFiltersOpenOnMobile(false);
    setInitialQueryParams(null);
  }, [history, initialQueryParams, onCloseModal, routeConfiguration]);

  // Close the filter modal
  const closeFilters = useCallback(() => {
    onCloseModal();

    setIsFiltersOpenOnMobile(false);
  }, [onCloseModal]);

  // Reset all filter query parameters
  const resetAllFilters = useCallback(
    e => {
      resetAll(e);

      // blur event target if event is passed
      if (e && e.currentTarget) {
        e.currentTarget.blur();
      }
    },
    [resetAll]
  );

  return (
    <div className={classes}>
      <div className={css.searchResultSummary}>
        {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
        {listingsAreLoaded && resultsCount === 0 ? noResults : null}
        {searchInProgress ? loadingResults : null}
      </div>
      <div className={css.filters} onClick={openFilters}>
        {filters}
      </div>
      <div className={css.buttons}>
        {sortByComponent}
        <div className={css.mapIcon} onClick={onMapIconClick}>
          <FormattedMessage id="SearchFiltersMobile.openMapView" className={css.mapIconText} />
        </div>
        <button className={css.clearFilters} onClick={clearFilters}>
          <FormattedMessage id="SearchFiltersPrimary.clearFilters" />
        </button>
      </div>
      <ModalInMobile
        id="SearchFiltersMobile.filters"
        isModalOpenOnMobile={isFiltersOpenOnMobile}
        onClose={cancelFilters}
        showAsModalMaxWidth={showAsModalMaxWidth}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        closeButtonMessage={modalCloseButtonMessage}
        contentClassName={css.modalContent}
        scrollLayerClassName={css.scrollLayer}
      >
        <div className={css.modalHeadingWrapper}>
          <button className={css.resetAllButton} onClick={resetAllFilters}>
            <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
          </button>
        </div>
        {isFiltersOpenOnMobile ? <div className={css.filtersWrapper}>{children}</div> : null}

        <div className={css.showListingsContainer}>
          <Button className={css.showListingsButton} onClick={closeFilters}>
            {showListingsLabel}
          </Button>
        </div>
      </ModalInMobile>
    </div>
  );
};

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
