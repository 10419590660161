import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '../../../components';
import { convertNumberToMoney, formatMoney } from '../../../util/currency';
import { CUSTOMER_FEE_PERCENTAGE } from '../../../transactions/transactionsUtil';
import { getCustomerFee, getNormalizedFee } from '../../../util/sessions';
import { LISTING_TYPE } from '../../../constants/listingAttributes';

import { propTypes } from '../../../util/types';

import css from './SearchMapGroupLabel.module.css';

class SearchMapGroupLabel extends Component {
  constructor(props) {
    super(props);

    this.state = { currentIndex: 0 };
    this.contentRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const hasSameAmountOfListings = nextProps.listings.length === this.props.listings.length;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    const isSameListingIndex = nextState.currentIndex === this.state.currentIndex;

    return !(
      hasSameAmountOfListings &&
      hasSameActiveStatus &&
      hasSameRefreshToken &&
      isSameListingIndex
    );
  }

  handleDoubleClick = e => {
    e.stopPropagation();
  };

  componentWillUnmount() {
    this.contentRef.current?.removeEventListener('dblclick', this.handleDoubleClick);
  }

  render() {
    const {
      className,
      rootClassName,
      listings,
      onListingClicked,
      isActive,
      markerId,
      currentUser,
      intl,
      isMobile,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const countLabelClasses = classNames(css.details, { [css.detailsActive]: isActive });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    const currentListing = listings?.[this.state.currentIndex] || {};

    const { author, attributes } = currentListing || {};

    const { price: listingPrice } = attributes || {};

    const isCurrentUserAuthor = currentUser?.id?.uuid === author.id?.uuid;

    const buyerFee = isCurrentUserAuthor ? CUSTOMER_FEE_PERCENTAGE : getCustomerFee(currentUser);

    const feeAmount =
      listingPrice?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

    const price = convertNumberToMoney(listingPrice?.amount + feeAmount, listingPrice?.currency);

    const isFree = currentListing?.attributes?.publicData?.type === LISTING_TYPE.FREE;

    const formattedPrice = price && price.currency ? formatMoney(intl, price) : price.currency;

    if (isMobile)
      return (
        <button className={classes} onClick={() => onListingClicked(listings)}>
          <div className={css.caretShadow} />
          <div className={countLabelClasses}>{listings.length}</div>
          <div className={caretClasses} />
        </button>
      );

    return (
      <Tooltip
        id={markerId}
        effect="solid"
        type="light"
        clickable
        delayHide={500}
        trigger={
          <div data-tip data-for={markerId} className={classes}>
            <div className={css.caretShadow} />
            <div className={countLabelClasses}>{listings.length}</div>
            <div className={caretClasses} />
          </div>
        }
        className={css.tooltip}
        afterShow={e => {
          this.contentRef.current?.addEventListener('dblclick', this.handleDoubleClick, false);
        }}
        afterHide={e => {
          this.contentRef.current?.removeEventListener('dblclick', this.handleDoubleClick);
        }}
        arrowColor="transparent"
      >
        <div className={css.content} ref={this.contentRef}>
          <button
            type="button"
            onClick={() => {
              onListingClicked(currentListing);
            }}
            className={css.listingPrice}
          >
            {isFree ? intl.formatMessage({ id: 'General.free' }) : formattedPrice}
          </button>
          <div className={classNames(css.paginationInfo)}>
            <button
              type="button"
              className={css.paginationPrev}
              onClick={() => {
                this.setState(oldState => ({
                  currentIndex: (oldState.currentIndex + listings.length - 1) % listings.length,
                }));
              }}
            />
            <div className={css.paginationPage}>{`${this.state.currentIndex + 1}/${
              listings.length
            }`}</div>
            <button
              type="button"
              className={css.paginationNext}
              onClick={() =>
                this.setState(oldState => ({
                  currentIndex: (oldState.currentIndex + listings.length + 1) % listings.length,
                }))
              }
            />
          </div>
        </div>
      </Tooltip>
    );
  }
}

SearchMapGroupLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { arrayOf, func, string } = PropTypes;

SearchMapGroupLabel.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingClicked: func.isRequired,
};

export default SearchMapGroupLabel;
