import React, { useMemo } from 'react';
import { string, bool } from 'prop-types';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import SortByPlain from './SortByPlain';
import SortByPopup from './SortByPopup';
import { useConfiguration } from '../../../context/configurationContext';

const DEFAULT_SORT_BY = '-attributes.createdAt';

const SortBy = props => {
  const { sort, showAsPopup, isConflictingFilterActive, intl, ...rest } = props;

  const config = useConfiguration();

  const { relevanceKey, queryParamName, options: opt } = config.search.sortConfig;

  const options = useMemo(
    () =>
      opt.map(option => {
        const isRelevance = option.key === relevanceKey;
        return {
          ...option,
          disabled:
            (isRelevance && !isConflictingFilterActive) ||
            (!isRelevance && isConflictingFilterActive),
        };
      }),
    [isConflictingFilterActive, opt, relevanceKey]
  );
  const componentProps = useMemo(
    () => ({
      urlParam: queryParamName,
      label: intl.formatMessage({ id: 'SortBy.heading' }),
      options,
      initialValue: isConflictingFilterActive ? relevanceKey : sort || DEFAULT_SORT_BY,
      ...rest,
    }),
    [intl, isConflictingFilterActive, options, queryParamName, relevanceKey, rest, sort]
  );

  return showAsPopup ? <SortByPopup {...componentProps} /> : <SortByPlain {...componentProps} />;
};

SortBy.defaultProps = {
  sort: null,
  showAsPopup: false,
};

SortBy.propTypes = {
  sort: string,
  showAsPopup: bool,
  isConflictingFilterActive: bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SortBy);
