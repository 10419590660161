import React, { useMemo, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { ensureListing } from '../../../util/data';
import { ListingCardWithoutLink } from '../../../components/ListingCard/ListingCard';
import { ListingCardCompactWithoutLink } from '../../../components/ListingCardCompact/ListingCardCompact';

import css from './SearchMapInfoCard.module.css';

const SearchMapInfoCard = props => {
  const {
    className,
    rootClassName,
    listings,
    isMobile,
    isTablet,
    createURLToListing,
    currentUser,
    config,
  } = props;

  const [currentListingIndex, setCurrentListingIndex] = useState(0);

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const currentListing = useMemo(() => ensureListing(listings[currentListingIndex]), [
    listings,
    currentListingIndex,
  ]);
  const { length: n } = listings;

  const paginationMaybe = useMemo(
    () =>
      n > 1 && (
        <div className={classNames(css.paginationInfo, { [css.paginationInfoMobile]: isMobile })}>
          <button
            type="button"
            className={css.paginationPrev}
            onClick={() => setCurrentListingIndex((currentListingIndex + n - 1) % n)}
          />
          <div className={css.paginationPage}>{`${currentListingIndex + 1}/${n}`}</div>
          <button
            type="button"
            className={css.paginationNext}
            onClick={() => setCurrentListingIndex((currentListingIndex + n + 1) % n)}
          />
        </div>
      ),
    [currentListingIndex, n, isMobile]
  );

  return (
    <div className={classes}>
      <a
        className={css.listingWrapper}
        href={createURLToListing(currentListing)}
        target="_blank"
        rel="noreferrer"
      >
        {isTablet ? (
          <ListingCardWithoutLink
            listing={currentListing}
            currentUser={currentUser}
            config={config}
          />
        ) : (
          <ListingCardCompactWithoutLink
            className={classNames({ [css.mobileListing]: isMobile, [css.paginatedListing]: n > 1 })}
            imageWrapperClassName={classNames({ [css.mobileListingImageWrapper]: isMobile })}
            listing={currentListing}
            showRating={false}
            currentUser={currentUser}
            config={config}
          />
        )}
      </a>
      {paginationMaybe}
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  isMobile: false,
  isTablet: false,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  isMobile: bool,
  isTablet: bool,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
};

export default SearchMapInfoCard;
