import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import { ErrorMessage, H4 } from '../../components';
import FilterComponent from './FilterComponent';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { translateSelectedActivities, validFilterParams } from './SearchPage.shared';
import { useConfiguration } from '../../context/configurationContext';
import SortBy from './SortBy/SortBy';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';

import css from './SearchPage.module.css';
import { useIntl } from 'react-intl';

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

const MainPanel = props => {
  const {
    className,
    rootClassName,
    urlQueryParams,
    listings,
    currentUser,
    searchInProgress,
    searchListingsError,
    searchParamsAreInSync,
    suggestedListings,
    onActivateListing,
    onManageDisableScrolling,
    onOpenModal,
    onCloseModal,
    onMapIconClick,
    onLoadMoreListings,
    pagination,
    search,
    showAsModalMaxWidth,
    history,
  } = props;

  const intl = useIntl();

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams);

  const { listingFields: listingFieldsConfig } = config?.listing || {};
  const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

  const classes = useMemo(() => classNames(rootClassName || css.searchResultContainer, className), [
    className,
    rootClassName,
  ]);

  const primaryFilters = useMemo(
    () => defaultFiltersConfig.filter(f => f.group === 'primary' && f.showOnSearchPage),
    [defaultFiltersConfig]
  );

  const selectedFilters = useMemo(
    () => validFilterParams(urlQueryParams, listingFieldsConfig, defaultFiltersConfig, false),
    [defaultFiltersConfig, listingFieldsConfig, urlQueryParams]
  );
  const selectedFiltersCount = useMemo(() => Object.keys(selectedFilters).length, [
    selectedFilters,
  ]);

  // With time-based availability filtering, pagination is NOT
  // supported. In these cases we get the pagination support info in
  // the response meta object, and we can use the count of listings
  // as the result count.
  //
  // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
  const hasPaginationInfo = useMemo(() => !!pagination && !pagination.paginationUnsupported, [
    pagination,
  ]);
  const totalItems = useMemo(
    () => (searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listings.length),
    [hasPaginationInfo, listings.length, pagination?.totalItems, searchParamsAreInSync]
  );
  const listingsAreLoaded = useMemo(() => !searchInProgress && searchParamsAreInSync, [
    searchInProgress,
    searchParamsAreInSync,
  ]);

  const conflictingFilterActive = useMemo(
    () => isAnyFilterActive(sortConfig.conflictingFilters, urlQueryParams, defaultFiltersConfig),
    [defaultFiltersConfig, sortConfig.conflictingFilters, urlQueryParams]
  );

  const handleSearch = useCallback(
    queryParams => {
      setCurrentQueryParams(queryParams);

      const search = cleanSearchFromConflictingParams(
        queryParams || currentQueryParams,
        sortConfig,
        defaultFiltersConfig
      );
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
    },
    [currentQueryParams, defaultFiltersConfig, history, routeConfiguration, sortConfig]
  );

  const sortByMaybe = useMemo(
    () =>
      sortConfig.active && (
        <SortBy
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={(urlParam, values) => {
            const newQueryParams = { ...urlQueryParams, [urlParam]: values };

            handleSearch(newQueryParams);
          }}
          showAsPopup
        />
      ),
    [
      conflictingFilterActive,
      handleSearch,
      sortConfig.active,
      sortConfig.queryParamName,
      urlQueryParams,
    ]
  );

  const initialValues = useCallback(
    queryParamNames => {
      // Get initial value for a given parameter from state if its there.
      const getInitialValue = paramName => {
        const currentQueryParam = currentQueryParams[paramName];
        const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
        // Discipline depends on activity and activity should be trusted from external
        // component (Topbar), thus conflicting discipline are handled outside of this component aswell
        const isTrustedQueryParam = paramName !== 'pub_discipline';
        return hasQueryParamInState && isTrustedQueryParam
          ? currentQueryParam
          : urlQueryParams[paramName];
      };

      // Return all the initial values related to given queryParamNames
      // InitialValues for "amenities" filter could be
      // { amenities: "has_any:towel,jacuzzi" }
      return Array.isArray(queryParamNames)
        ? queryParamNames.reduce((acc, paramName) => {
            return { ...acc, [paramName]: getInitialValue(paramName) };
          }, {})
        : {};
    },
    [currentQueryParams, urlQueryParams]
  );

  const handleFilterValueChange = useCallback(
    updatedURLParams => {
      const { pub_activity, address, bounds, ...rest } = urlQueryParams;
      const mergedQueryParams = { ...rest, ...currentQueryParams };

      // Handled outside of MainPanel.
      // I.e. TopbarSearchForm && search by moving the map.
      // We should always trust urlQueryParams with those.
      const handledOutside = { pub_activity, address, bounds };

      const newQueryParams = { ...mergedQueryParams, ...updatedURLParams, ...handledOutside };

      handleSearch(newQueryParams);
    },
    [currentQueryParams, handleSearch, urlQueryParams]
  );

  const clearFilters = useCallback(() => {
    handleSearch({});
  }, [handleSearch]);

  // Discipline filter options are added/removed here if a user has selected/unselected-all the activity/ies
  useEffect(() => {
    if (selectedFilters.pub_activity) {
      const splitActivityParam = selectedFilters.pub_activity.split(':');

      // If we have a second split param, it means we have 'has_any' or smth. as first
      const onlyActivityParams = splitActivityParam[1] || splitActivityParam[0];

      const selectedActivities = onlyActivityParams?.split(',') || [];
      const activityFilters = defaultFiltersConfig.filter(f =>
        selectedActivities.some(a => a === f.id)
      );
      const disciplineFilter = defaultFiltersConfig.filter(f => f.id === 'discipline');

      const disciplineOptions = [];
      // Add selected activity options to discipline options
      activityFilters.forEach(f => disciplineOptions.push(...f.config.options));
      const discipline = {
        ...disciplineFilter[0],
        config: { ...disciplineFilter[0].config, options: disciplineOptions },
      };

      const existingDisciplineFilter = primaryFilters.findIndex(f => f.id === 'discipline');
      if (existingDisciplineFilter !== -1) {
        // Replace existing discipline filter
        primaryFilters.splice(existingDisciplineFilter, 1, discipline);
      } else {
        // Show discipline filter on the page after the activity filter if present
        primaryFilters.splice(0, 0, discipline);
      }
    } else {
      const disciplineIndex = primaryFilters.findIndex(f => f.id === 'discipline');
      if (disciplineIndex > -1) {
        primaryFilters.splice(disciplineIndex, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.pub_activity]);

  // Reusable
  const filterComponents = useCallback(
    showAsPopup =>
      primaryFilters.map(filterConfig => (
        <FilterComponent
          key={`SearchFiltersMobile.${filterConfig.id}`}
          idPrefix="SearchFiltersMobile"
          filterConfig={filterConfig}
          urlQueryParams={urlQueryParams}
          initialValues={initialValues}
          onValueChange={handleFilterValueChange}
          showAsPopup={showAsPopup}
          marketplaceCurrency={config.currency}
          liveEdit
        />
      )),
    [config.currency, handleFilterValueChange, initialValues, primaryFilters, urlQueryParams]
  );

  return (
    <div className={classes}>
      {urlQueryParams?.pub_activity && urlQueryParams?.address ? (
        <H4 as="h1" className={css.selectedActivityTitle}>
          <span>{translateSelectedActivities(intl, urlQueryParams.pub_activity)}</span>
          {urlQueryParams.address.split(',')?.[0]}
        </H4>
      ) : null}
      <SearchFiltersPrimary
        className={css.searchFiltersPrimary}
        sortByComponent={sortByMaybe}
        listingsAreLoaded={listingsAreLoaded}
        resultsCount={totalItems}
        searchInProgress={searchInProgress}
        searchListingsError={searchListingsError}
        clearFilters={clearFilters}
      >
        {filterComponents(true)}
      </SearchFiltersPrimary>
      <SearchFiltersMobile
        className={css.searchFiltersMobile}
        urlQueryParams={urlQueryParams}
        sortByComponent={sortByMaybe}
        listingsAreLoaded={listingsAreLoaded}
        resultsCount={totalItems}
        searchInProgress={searchInProgress}
        searchListingsError={searchListingsError}
        showAsModalMaxWidth={showAsModalMaxWidth}
        onMapIconClick={onMapIconClick}
        onManageDisableScrolling={onManageDisableScrolling}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        resetAll={() => handleSearch({})}
        filters={filterComponents(true)}
        clearFilters={clearFilters}
        selectedFiltersCount={selectedFiltersCount}
      >
        {filterComponents(false)}
      </SearchFiltersMobile>
      <div className={css.searchResultsWrapper}>
        {searchListingsError && <ErrorMessage />}
        <SearchResultsPanel
          className={css.searchListingsPanel}
          listings={listings}
          currentUser={currentUser}
          pagination={listingsAreLoaded ? pagination : null}
          search={search}
          suggestedListings={suggestedListings}
          searchInProgress={searchInProgress}
          setActiveListing={onActivateListing}
          onLoadMoreListings={onLoadMoreListings}
        />
      </div>
    </div>
  );
};

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  pagination: null,
  search: null,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  listings: array,
  pagination: propTypes.pagination,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  search: string.isRequired,

  urlQueryParams: object.isRequired,
  showAsModalMaxWidth: number.isRequired,

  onActivateListing: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
