import React, { useState } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import css from './SortByPopup.module.css';
import { useIntl } from 'react-intl';

const optionLabel = (options, key, intl) => {
  const option = options.find(o => o.key === key);
  return option ? intl.formatMessage({ id: option.label }) : key;
};

const SortByIcon = () => {
  return (
    <svg className={css.icon} width="10" height="16" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3.25 7.125v7.438M5 12.813l-1.75 1.75-1.75-1.75M6.75 8.875V1.438M5 3.188l1.75-1.75 1.75 1.75" />
      </g>
    </svg>
  );
};

const SortByPopup = props => {
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  const {
    rootClassName,
    className,
    menuLabelRootClassName,
    urlParam,
    label,
    options,
    initialValue,
    contentPlacementOffset,
    onSelect,
  } = props;

  const onToggleActive = isOpenParam => {
    setIsOpen(isOpenParam);
  };

  const selectOption = (urlParameter, option) => {
    setIsOpen(false);
    onSelect(urlParameter, option);
  };

  // resolve menu label text and class
  const menuLabel = initialValue ? optionLabel(options, initialValue, intl) : label;

  const classes = classNames(rootClassName || css.root, className);
  const menuLabelClasses = classNames(menuLabelRootClassName || css.menuLabel);
  const menuContentClasses = classNames(css.menuContent, { [css.isOpen]: isOpen });

  return (
    <Menu
      className={classes}
      useArrow={false}
      contentPlacementOffset={contentPlacementOffset}
      contentPosition="right"
      onToggleActive={onToggleActive}
      isOpen={isOpen}
    >
      <MenuLabel rootClassName={menuLabelClasses}>
        <SortByIcon />
        {menuLabel}
      </MenuLabel>
      <MenuContent className={menuContentClasses}>
        <MenuItem key="sort-by">
          <h4 className={css.menuHeading}>{label}</h4>
        </MenuItem>
        {options.map(option => {
          // check if this option is selected
          const selected = initialValue === option.key;
          // menu item border class
          const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;
          const menuItemClass = classNames(css.menuItem, { [css.menuItemSelected]: selected });

          return (
            <MenuItem key={option.key}>
              <button
                className={menuItemClass}
                disabled={option.disabled}
                onClick={() => (selected ? null : selectOption(urlParam, option.key))}
              >
                <span className={menuItemBorderClass} />
                {option.longLabel || intl.formatMessage({ id: option.label })}
              </button>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
