import React, { useCallback, useMemo } from 'react';
import { arrayOf, bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import {
  ListingCard,
  PaginationLinks,
  SecondaryButton,
  SectionExperience,
} from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { parse, stringify } from '../../../util/urlHelpers';

import css from './SearchResultsPanel.module.css';
import { useSelector } from 'react-redux';
import { useConfiguration } from '../../../context/configurationContext';

const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    suggestedListings,
    searchInProgress,
    setActiveListing,
    onLoadMoreListings,
  } = props;

  const { currentUser } = useSelector(state => state.user);
  const config = useConfiguration();

  const { currentPage, totalPages } = pagination || {};

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const shouldShowLoadMore = useMemo(
    () => listings.length !== 0 && (searchInProgress || totalPages > currentPage),
    [listings.length, currentPage, totalPages, searchInProgress]
  );

  const hasSuggestedListings = useMemo(() => suggestedListings.length > 0, [
    suggestedListings.length,
  ]);

  const queryParams = parse(search);

  const hasPageQueryParam = queryParams.page > 0;

  const loadMore = useCallback(() => {
    const searchPaginated = stringify({ ...queryParams, page: currentPage + 1 });

    onLoadMoreListings(searchPaginated);
  }, [queryParams, currentPage, onLoadMoreListings]);

  return (
    <div className={classes}>
      {listings.map(l => (
        <ListingCard
          className={css.listingCard}
          key={l.id.uuid}
          id={`listing-${l.id.uuid}`}
          listing={l}
          renderSizes={cardRenderSizes}
          setActiveListing={setActiveListing}
          showOnlyAverageReview
          currentUser={currentUser}
          config={config}
        />
      ))}
      {shouldShowLoadMore && !hasPageQueryParam && (
        <SecondaryButton
          className={css.paginationContainer}
          disabled={searchInProgress}
          inProgress={searchInProgress}
          onClick={loadMore}
        >
          <FormattedMessage id="General.loadMore" />
        </SecondaryButton>
      )}

      {hasSuggestedListings ? (
        <>
          <div className={css.suggestedListings}>
            <FormattedMessage id="SearchPage.suggestedListings" />
          </div>
          {suggestedListings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              id={`listing-${l.id.uuid}`}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              showOnlyAverageReview
              currentUser={currentUser}
              config={config}
            />
          ))}
        </>
      ) : null}

      {pagination ? (
        <PaginationLinks
          className={classNames(css.pagination, { [css.paginationVisible]: hasPageQueryParam })}
          pageName="SearchPage"
          pageSearchParams={queryParams}
          pagination={pagination}
        />
      ) : null}

      {!searchInProgress && <SectionExperience className={css.contactBanner} />}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  listings: [],
  pagination: null,
  searchInProgress: false,
  search: null,
};

SearchResultsPanel.propTypes = {
  className: string,
  rootClassName: string,
  children: node,
  listings: arrayOf(propTypes.listing),
  pagination: propTypes.pagination,
  searchInProgress: bool,
  search: string,
};

export default SearchResultsPanel;
